import { useState, useEffect } from "react";

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [billingDetails, setBillingDetails] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    postal: "",
    phone: "",
    email: "",
  });

  const [paymentMethod, setPaymentMethod] = useState("bankTransfer");

  useEffect(() => {
    // Fetch cart items from local storage or API
    const storedItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(storedItems);
    calculateTotalPrice(storedItems);
  }, []);

  const calculateTotalPrice = (items) => {
    const total = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
    setTotalPrice(total);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handlePlaceOrder = async () => {
    if (!billingDetails.firstName || !billingDetails.lastName || !billingDetails.email) {
      alert("Please fill in all required fields.");
      return;
    }

    const salesData = {
      FirstName: billingDetails.firstName,
      LastName: billingDetails.lastName,
      Email: billingDetails.email,
      Phone: billingDetails.phone,
      City: billingDetails.city,
      Address1: billingDetails.address1,
      Address2: billingDetails.address2,
      Postal: billingDetails.postal,
      Description: 'Order description', // Add appropriate description
      LastUpdated: new Date()
    };

    const salesDetails = cartItems.map(item => ({
      ProductID: item.ProductID,
      ACIN: item.ACIN,
      PartNumber: item.PartNumber,
      Gen: item.Gen,
      CPU: item.CPU,
      RAM: item.RAM,
      Storage: item.Storage,
      QTY: item.quantity,
      SalePrice: item.price,
      AmountReceived: item.price * item.quantity,
      BankTransfer: paymentMethod === 'bankTransfer' ? item.price * item.quantity : 0,
      created_at: new Date(),
      updated_at: new Date()
    }));

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/orders/checkout`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ salesData, salesDetails }),
      });

      if (response.ok) {
        alert("Order placed successfully!");
        localStorage.removeItem("cartItems"); // Clear cart after order placement
        setCartItems([]);
      } else {
        const errorData = await response.json();
        console.error("Failed to place order:", errorData);
        alert("Failed to place order.");
      }
    } catch (error) {
      console.error("Error placing order:", error.message);
    }
  };

  const bg =
    "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a";

  return (
    <section
      className="py-10 px-4 min-h-screen"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
    <div className="max-w-7xl mx-auto grid grid-cols-8 mb-10 gap-4">
      {/* Billing Details */}
      <div className="col-span-4">
        <h3 className="mt-5 p-2 text-xl font-bold text-gray-800">Billing Details</h3>
        <form>
          <div className="mb-6 grid gap-6 md:grid-cols-2">
            <div className="p-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                First name
              </label>
              <input
                type="text"
                name="firstName"
                value={billingDetails.firstName}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500"
                placeholder="John"
                required
              />
            </div>
            <div className="p-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Last name
              </label>
              <input
                type="text"
                name="lastName"
                value={billingDetails.lastName}
                onChange={handleInputChange}
                className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500"
                placeholder="Doe"
                required
              />
            </div>
          </div>
          {/* Other billing details */}
          <div className="p-2">
            <label className="mb-2 block text-sm font-medium text-gray-900">Email address</label>
            <input
              type="email"
              name="email"
              value={billingDetails.email}
              onChange={handleInputChange}
              className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500"
              placeholder="john.doe@example.com"
              required
            />
          </div>
          <div className="p-2">
            <label className="mb-2 block text-sm font-medium text-gray-900">Phone</label>
            <input
              type="Phone"
              name="phone"
              value={billingDetails.phone}
              onChange={handleInputChange}
              className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500"
              placeholder="0300 123 4567"
              required
            />
          </div>
          <div className="p-2">
            <label className="mb-2 block text-sm font-medium text-gray-900">Street address</label>
            <input
              type="address1"
              name="address1"
              value={billingDetails.address1}
              onChange={handleInputChange}
              className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500"
              placeholder="Abc St 123, colony"
              required
            />
          </div>
          <div className="p-2">
            <label className="mb-2 block text-sm font-medium text-gray-900"></label>
            <input
              type="address2"
              name="address2"
              value={billingDetails.address2}
              onChange={handleInputChange}
              className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500"
              placeholder="Apartment, studio, or floor"
              required
            />
          </div>
          <div className="p-2">
            <label className="mb-2 block text-sm font-medium text-gray-900">City</label>
            <input
              type="city"
              name="city"
              value={billingDetails.city}
              onChange={handleInputChange}
              className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500"
              placeholder="Abc St 123, colony"
              required
            />
          </div>
          <div className="p-2">
            <label className="mb-2 block text-sm font-medium text-gray-900">Postal Code</label>
            <input
              type="postal"
              name="postal"
              value={billingDetails.postal}
              onChange={handleInputChange}
              className="block w-full rounded-lg border border-orange-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-orange-500 focus:ring-orange-500"
              placeholder="Abc St 123, colony"
              required
            />
          </div>
          {/* Add more input fields for other billing details */}
        </form>
      </div>

      {/* Order Summary */}
      <div className="col-span-4">
        <h3 className="mt-5 text-xl font-bold text-gray-800 mb-10">Order Summary</h3>
        <ul className="mb-4">
          {cartItems.map((item) => (
            <li key={item.ProductID} className="flex justify-between mb-2">
              <span>
                {item.ProductName} (x{item.quantity})
              </span>
              <span>Rs. {item.price * item.quantity}</span>
            </li>
          ))}
        </ul>

        <div className="flex justify-between font-bold">
          <span>Total:</span>
          <span>Rs. {totalPrice.toLocaleString()}</span>
        </div>

        {/* Payment Options */}
        <div className="mt-10 flex flex-col bg-gray-100 bg-opacity-50 rounded-md p-10 gap-10">
          {/* Radio button for Direct bank transfer */}
          <div className="inline-flex items-center">
            <label className="relative flex cursor-pointer items-center">
              <input
                className="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 transition-all checked:border-slate-400" 
                type="radio"
                name="paymentMethod"
                value="bankTransfer"
                checked={paymentMethod === "bankTransfer"}
                onChange={() => setPaymentMethod("bankTransfer")}
              />
              <span className="absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-slate-800 opacity-0 transition-opacity duration-200 peer-checked:opacity-100"></span>
            </label>
            <label className="ml-2 cursor-pointer text-sm text-slate-600">
              <div>
                <p className="font-medium">Direct bank transfer</p>
              </div>
            </label>
          </div>
          <p className="ms-7 rounded-md bg-orange-100 p-4 text-slate-500">
            Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.
          </p>
          {/* Radio button for Cash on Delivery */}
          <div className="inline-flex items-center">
            <label className="relative flex cursor-pointer items-center">
              <input
                className="peer h-5 w-5 cursor-pointer appearance-none rounded-full border border-slate-300 transition-all checked:border-slate-400" 
                type="radio"
                name="paymentMethod"
                value="cashOnDelivery"
                checked={paymentMethod === "cashOnDelivery"}
                onChange={() => setPaymentMethod("cashOnDelivery")}
              />
              <span className="absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-slate-800 opacity-0 transition-opacity duration-200 peer-checked:opacity-100"></span>
            </label>
            <label className="ml-2 cursor-pointer text-sm text-slate-600">
              <div>
                <p className="font-medium">Cash On Delivery</p>
              </div>
            </label>
          </div>
        </div>

        <button
          onClick={handlePlaceOrder}
          className="mt-4 w-full bg-orange-500 text-white py-2 rounded"
        >
          Place Order
        </button>
      </div>
    </div>
  </section>
  );
};

export default Checkout;