import React, { useState, useEffect } from 'react';

function AddProducts() {
  // eslint-disable-next-line
  const [products, setProducts] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]); // To store media library files
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false); // Modal state
  const [formData, setFormData] = useState({
    ACIN: '',
    DealCode: '',
    PartNumber: '',
    SerialNumber: '',
    ProductName: '',
    slug: '',
    Category: '',
    Brand: '',
    processor: '',
    generation: '',
    ramMemory: '',
    storage: '',
    screenSize: '',
    displayType: '',
    graphicsCard: '',
    color: '',
    model: '',
    bodyDesign: '',
    stockQuantity: 0,
    Condition: '',
    price: '',
    UnitPrice: '',
    OutletCode: '',
    Status: '',
    imageUrl: null,
    imageArray: [],
    videoUrl: '',
  });

  useEffect(() => {
    fetchProducts();
    fetchMediaFiles(); // Fetch media library files
    generateACIN();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products`);
      const data = await response.json();
      
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const generateACIN = () => {
    const timestamp = new Date().getTime();
    const randomSuffix = Math.floor(1000 + Math.random() * 9000);
    const acin = `ACIN-${timestamp}-${randomSuffix}`;
    setFormData((prevData) => ({ ...prevData, ACIN: acin }));
  };

  const fetchMediaFiles = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/media`);
      const data = await response.json();
      setMediaFiles(data.files);
    } catch (error) {
      console.error('Error fetching media files:', error);
    }
  };

  const handleImageSelect = (imageUrl) => {
    setFormData((prevData) => ({ ...prevData, imageUrl }));
    setIsMediaModalOpen(false); // Close the modal after selecting
  };

  const handleImageArraySelect = (selectedImages) => {
    setFormData((prevData) => ({
      ...prevData,
      imageArray: [...prevData.imageArray, ...selectedImages],
    }));
    setIsMediaModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Upload the main image to the /cdn folder first
    let uploadedImageUrl = '';
    if (formData.imageUrl) {
      const imageFormData = new FormData();
      imageFormData.append('imageUrl', formData.imageUrl);
  
      try {
        const uploadResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/upload`,
          {
            method: 'POST',
            body: imageFormData,
          }
        );
  
        if (uploadResponse.ok) {
          const uploadData = await uploadResponse.json();
          uploadedImageUrl = uploadData.imageUrl; // Get the uploaded image URL
        } else {
          console.error('Image upload failed:', await uploadResponse.text());
          return;
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        return;
      }
    }
  
    // Upload additional images
    const uploadedImageArray = [];
    if (formData.imageArray.length > 0) {
      for (const image of formData.imageArray) {
        const imageFormData = new FormData();
        imageFormData.append('imageArray', image);
  
        try {
          const uploadResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/upload`,
            {
              method: 'POST',
              body: imageFormData,
            }
          );
  
          if (uploadResponse.ok) {
            const uploadData = await uploadResponse.json();
            uploadedImageArray.push(uploadData.imageUrl); // Append the uploaded image URL
          } else {
            console.error('Image array upload failed:', await uploadResponse.text());
            return;
          }
        } catch (error) {
          console.error('Error uploading image array:', error);
          return;
        }
      }
    }
  
    // Now, save the product details along with the URLs
    const productData = {
      ...formData,
      imageUrl: uploadedImageUrl,
      imageArray: uploadedImageArray,
    };
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/products/add`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(productData),
        }
      );
  
      if (response.ok) {
        fetchProducts(); // Refresh the product list
        alert('Product added successfully!');
      } else {
        console.error('Failed to add product:', await response.text());
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };
  
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'imageUrl') {
      setFormData({ ...formData, imageUrl: files[0] });
    } else if (name === 'imageArray') {
      setFormData({ ...formData, imageArray: Array.from(files) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div className="min-h-screen p-6">

      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg max-w-6xl mx-auto mb-8">
        <h2 className="text-xl font-semibold mb-4">Add New Product</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Fields */}
          {Object.keys(formData).map((field) => (
            <div key={field}>
              <label className="block text-sm font-medium text-gray-700" htmlFor={field}>
                {field}
              </label>
            {field === 'imageUrl' || field === 'imageArray' ? (
              <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="mainImage">
                  Main Image
                </label>
                <button
                  type="button"
                  onClick={() => setIsMediaModalOpen(true)}
                  className="mt-2 p-2 bg-blue-500 text-white rounded"
                >
                  Select Main Image
                </button>
                {formData.imageUrl && (
                  <div className="mt-2">
                    <img src={formData.imageUrl} alt="Selected" className="w-24 h-24 object-cover" />
                  </div>
                )}
              </div>
            ) : (
              <div>
                {/* Additional Images */}
                <label className="block text-sm font-medium text-gray-700" htmlFor="additionalImages">
                  Additional Images
                </label>
                <button
                  type="button"
                  onClick={() => setIsMediaModalOpen(true)}
                  className="mt-2 p-2 bg-blue-500 text-white rounded"
                >
                  Select Additional Images
                </button>
                {formData.imageArray.length > 0 && (
                  <div className="mt-2 flex gap-2">
                    {formData.imageArray.map((image, idx) => (
                      <img key={idx} src={image} alt={`Additional-${idx}`} className="w-16 h-16 object-cover" />
                    ))}
                  </div>
                )}
              </div>
            )}
            </div>
          ))}

          {/* Submit Button */}
          <button type="submit" className="mt-6 w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Add Product
          </button>
        </div>
      </form>
      {/* Media Library Modal */}
      {isMediaModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-3xl w-full">
            <h3 className="text-lg font-semibold mb-4">Media Library</h3>
            <div className="grid grid-cols-3 gap-4">
              {mediaFiles.map((file, idx) => (
                <div key={idx} className="border p-2">
                  <img
                    src={file}
                    alt={`Media-${idx}`}
                    className="w-full h-32 object-cover cursor-pointer"
                    onClick={() => handleImageSelect(file)}
                  />
                </div>
              ))}
            </div>
            <button
              onClick={() => setIsMediaModalOpen(false)}
              className="mt-4 p-2 bg-red-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddProducts;
