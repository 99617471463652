import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"; // Import styles
import { useParams } from 'react-router-dom';

const ProductImages = () => {
    const { slug } = useParams();
    console.log('Product Name from URL:', slug);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!slug) {
      setError('Product name is missing.');
      return;
    }
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await fetch(`http://www.abidcomputersmultan.com:3000/api/products/grouped/${slug}`);
        if (!response.ok) {
          throw new Error('Failed to fetch product');
        }
        const data = await response.json();
        setProduct(data);
      } catch (err) {
        console.error('Error in fetchProduct:', err);
        setError('Failed to load product details');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [slug]);

  if (loading) return <div className="text-center text-gray-500">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  const images = [];
  if (product?.imageUrl) {
    images.push({
      original: product.imageUrl,
      thumbnail: product.imageUrl,
    });
  }
  if (product?.imageArray && Array.isArray(product.imageArray)) {
    product.imageArray.forEach((image) => {
      images.push({
        original: image,
        thumbnail: image,
      });
    });
  }

    return (
        <div className="max-w-8xl">
        <div className="mb-6">
        <ImageGallery items={images} showPlayButton={false} />
      </div>
        {/* Video Section */}
        {product.videoUrl && (
          <div className="mt-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-2">Product Video:</h2>
            <video controls className="w-full rounded shadow-lg">
              <source src={product.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    );
};

export default ProductImages;
