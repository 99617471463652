import { useState } from "react";
import ManageProducts from './ManageProducts';
import AddProduct from './AddProduct';
import SalesReports from './SalesReports';
import FileUpload from "../components/FileUpload";
import MediaLibrary from "../components/MediaLibrary";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("manage-products");

  const renderTabContent = () => {
    switch (activeTab) {
      case "manage-products":
        return <ManageProducts />;
      case "add-product":
        return <AddProduct />;
      case "sales-reports":
        return <SalesReports />;
      case "file-upload":
        return <FileUpload />;
      case "media-library":
        return <MediaLibrary />;
      default:
        return <ManageProducts />;
    }
  };

  return (
    <div className="min-h-screen flex bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-white shadow-lg">
        <div className="h-16 flex items-center justify-center font-bold text-xl text-orange-600">
          Dashboard
        </div>
        <ul className="space-y-2 mt-8">
          <li>
            <button
              onClick={() => setActiveTab("manage-products")}
              className={`w-full text-left px-6 py-3 font-semibold ${
                activeTab === "manage-products"
                  ? "bg-orange-600 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`}
            >
              Manage Products
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("add-product")}
              className={`w-full text-left px-6 py-3 font-semibold ${
                activeTab === "add-product"
                  ? "bg-orange-600 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`}
            >
              Add Product
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("sales-reports")}
              className={`w-full text-left px-6 py-3 font-semibold ${
                activeTab === "sales-reports"
                  ? "bg-orange-600 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`}
            >
              Sales Reports
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("file-upload")}
              className={`w-full text-left px-6 py-3 font-semibold ${
                activeTab === "file-upload"
                  ? "bg-orange-600 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`}
            >
              File Upload
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("media-library")}
              className={`w-full text-left px-6 py-3 font-semibold ${
                activeTab === "media-library"
                  ? "bg-orange-600 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`}
            >
              Media Library
            </button>
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
