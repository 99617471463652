import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faArrowUp, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import "react-image-gallery/styles/css/image-gallery.css";
import ProductComparison from '../components/ProductComparison';
import ProductImages from '../components/ProductImages';
import ProductList from '../components/ProductList';
import { useComparison } from '../context/CompareContext';

const ProductDetails = () => {
  const { addToComparison } = useComparison();
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    if (!slug) {
      setError('Product name is missing.');
      return;
    }
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/grouped/${slug}`);
        if (!response.ok) {
          throw new Error('Failed to fetch product');
        }
        const data = await response.json();
        console.log('Fetched product:', data); // Log the full product data
        console.log('Representative Product ID:', data.representativeProductID);
        setProduct({ ...data, slug });
      } catch (err) {
        console.error('Error in fetchProduct:', err);
        setError('Failed to load product details');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
    const handleScroll = () => setShowBackToTop(window.pageYOffset > 300);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [slug]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  const handleAddToCart = async () => {
    try {
      const representativeProductId = product.representativeProductID; // Use the correct casing
  
      if (!representativeProductId) {
        alert('This product is unavailable or missing the representative ID.');
        return;
      }
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/cart/add`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ productId: representativeProductId, quantity: 1 }),
      });
  
      const data = await response.json();
      alert(data.message);
  
      // Assuming the response contains the product data
      const addedProduct = {
        id: representativeProductId,
        ProductName: data.product.ProductName,
        price: data.product.price,
        quantity: 1,
        imageUrl: data.product.imageUrl, // Assuming this is in the response
        ProductID: data.product.ProductID,
        ACIN: data.product.ACIN,
      };
  
      // Update the cart in localStorage
      const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      cartItems.push(addedProduct);
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    } catch (err) {
      console.error('Error adding to cart:', err);
    }
  };
  
  
  
  if (error) return <div>{error}</div>;
  if (loading) return <div>Loading...</div>;
  if (!product) return <div>No product data available</div>;

  return (
    <section className='bg-gray-50'>
      <div className="container mx-auto px-4 py-8">
        <nav className="text-sm mb-6 mt-10" aria-label="Breadcrumb">
          <ol className="list-none p-0 inline-flex">
            <li className="flex items-center">
              <Link to="/" className="text-orange-600 hover:underline">Home</Link>
              <span className="mx-2">/</span>
            </li>
            <li className="flex items-center text-gray-500">{product.slug}</li>
          </ol>
        </nav>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Image Gallery */}
          <ProductImages  />
          {/* <ImageGallery productName={product.ProductName} /> */}
          {/* Product Details */}
          <div className="lg:col-span-1 p-10 bg-white rounded-lg shadow-md">
            <h1 className="text-4xl font-bold mb-4">{product.productName}</h1>
            <h2 className="text-2xl text-gray-600 mb-1">Brand: {product.brand}</h2>
            <h2 className="text-2xl text-gray-600 mb-1">Processor: {product.processor}</h2>
            <h2 className="text-2xl text-gray-600 mb-1">RAM: {product.ramMemory}</h2>
            <h2 className="text-2xl text-gray-600 mb-1">Storage: {product.storage}</h2>
            <h2 className="text-2xl text-gray-600 mb-1">Generation: {product.generation}</h2>
            <h2 className="text-2xl text-gray-600 mb-1">Screen Size: {product.screenSize}</h2>
            <h2 className="text-2xl text-gray-600 mb-1">Display Type: {product.displayType}</h2>
            <h2 className="text-2xl text-gray-600 mb-1">Graphics Card: {product.graphicsCard}</h2>
            {/* <h2 className="text-2xl text-gray-600 mb-1">Price: Rs. {product.price.toLocaleString()}</h2> */}
            <div className="mt-4 flex">
              <button onClick={handleAddToCart} className=" w-30 justify-center gap-x-1.5 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white hover:bg-orange-600 shadow-md transition-all">
                ADD TO CART
              </button>
              <Link to="/cart" className="ms-4 top-4 right-4">
                <button className="text-orange-500 text-3xl hover:text-gray-900 transition-all">
                  <FontAwesomeIcon icon={faCartShopping} />
                </button>
              </Link>
              <button
                onClick={() => {
                  if (!product) {
                    console.error('Product data is undefined.');
                    return;
                  }
                  if (!product.slug) {
                  console.error('Product slug is required to add to the comparison list.', product);
                  return;
                  }
                  addToComparison({
                    slug: product.slug,
                    ProductName: product.ProductName || 'N/A',
                    imageUrl: product.imageUrl || 'https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/woocommerce-placeholder.png?alt=media&token=27e9da3d-7159-4a01-a9ed-0a7d77473357',
                    price: product.price || 0,
                    processor: product.processor || 'N/A',
                    generation: product.generation || 'N/A',
                    ramMemory: product.ramMemory || 'N/A',
                    storage: product.storage || 'N/A',
                    screenSize: product.screenSize || 'N/A',
                    graphicsCard: product.graphicsCard || 'N/A',
                    displayType: product.displayType || 'N/A',
                  });
                }}
              >
                <FontAwesomeIcon icon={faBalanceScale} className="mt-2 text-white bg-gradient-to-r from-green-500 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center ms-4 me-2 mb-2" />
              </button>
            </div>
          </div>
        </div>
        
        {/* Comparison Section Call */}
        <ProductComparison currentProduct={product} />
        {/* Related Products */}
        <div className="mt-10 mb-10">
          <h2 className="text-xl font-semibold mb-4">Related Products</h2>
          <ProductList categoryName={product.Category} />
        </div>
      </div>
      {showBackToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-6 right-6 bg-gray-500 text-white rounded-full p-4 shadow-lg hover:bg-orange-600 transition"
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}
    </section>
  );
};
export default ProductDetails;