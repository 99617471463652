import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function ManageProducts() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('http://localhost:3000/api/products');
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        const response = await fetch(`http://localhost:3000/api/products/${productId}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          fetchProducts(); // Refresh the product list
        } else {
          console.error('Failed to delete product:', await response.text());
        }
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  return (
    <div className="min-h-screen p-6">
      <h2 className="text-xl font-semibold mb-4">Manage Products</h2>
      <table className="w-full border border-gray-200">
        <thead>
          <tr>
            <th className="border border-gray-200 p-2">Product Name</th>
            <th className="border border-gray-200 p-2">Category</th>
            <th className="border border-gray-200 p-2">Brand</th>
            <th className="border border-gray-200 p-2">Price</th>
            <th className="border border-gray-200 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.ProductID}>
              <td className="border border-gray-200 p-2">{product.ProductName}</td>
              <td className="border border-gray-200 p-2">{product.Category}</td>
              <td className="border border-gray-200 p-2">{product.Brand}</td>
              <td className="border border-gray-200 p-2">${product.price}</td>
              <td className="border border-gray-200 p-2">
              <Link to={`/edit-product/${product.ProductID}`}>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
                  Edit
                </button>
              </Link>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => handleDelete(product.ProductID)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ManageProducts;