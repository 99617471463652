import React, { useState, useEffect } from 'react';
import axios from 'axios';

function MediaLibrary({ onSelect }) {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMediaFiles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/media`);
        console.log('Media response:', response.data); // Debug log
        if (response.data && Array.isArray(response.data.files)) {
          setMediaFiles(response.data.files);
        } else {
          console.error('Invalid media response format:', response.data);
          setMediaFiles([]); // Set to empty array on error
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching media files:', error);
        setMediaFiles([]); // Set to empty array on error
        setLoading(false);
      }
    };
    fetchMediaFiles();
  }, []);
  

  if (loading) return <p>Loading media...</p>;

  return (
    <div>
      <h3>Media Library</h3>
      <div className="flex flex-wrap gap-4">
        {mediaFiles && mediaFiles.length > 0 ? (
          mediaFiles.map((file, index) => (
            <div key={index} className="w-24 cursor-pointer">
              <img
                src={file}
                alt="media"
                className="w-full rounded-2xl"
                onClick={() => onSelect(file)}
              />
            </div>
          ))
        ) : (
          <p>No media files available.</p>
        )}
      </div>
    </div>
  );
  
}

export default MediaLibrary;
