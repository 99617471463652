import React from 'react';
import { useComparison } from '../context/CompareContext';

const ProductComparison = () => {
  const { comparisonList, removeFromComparison } = useComparison();
  console.log('Comparison List:', comparisonList); // Debugging output

  if (comparisonList.length === 0) {
    return <p className="text-center">No products added for comparison.</p>;
  }

  return (
    <div className="relative flex-col justify-center overflow-hidden bg-gray-50 py-6 text-center">
      <div className="relative bg-white px-6 pb-8 pt-10 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:rounded-lg sm:px-10">
        <div className="overflow-x-auto">
          <table className="w-full border border-collapse">
            <thead>
              <tr className="border">
                <th className="p-2 text-gray-700">&nbsp;</th>
                {comparisonList.map((product) => (
                  <th className="border p-2" key={product.slug} aria-label={`Product Name: ${product.ProductName}`}>
                    <div className="flex flex-col items-center">
                      <img
                        className="w-40 h-auto md:w-52"
                        src={product.imageUrl || 'https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/woocommerce-placeholder.png?alt=media&token=27e9da3d-7159-4a01-a9ed-0a7d77473357'}
                        alt={product.ProductName || 'Product'}
                      />
                      <span className="text-sm font-bold">{product.ProductName}</span>
                      <h6 className="text-sm font-bold">Rs. {product.price}/-</h6>
                      <button
                        className="text-red-500 hover:underline mt-2"
                        onClick={() => removeFromComparison(product.slug)}
                      >
                        Remove
                      </button>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {['processor', 'generation', 'ramMemory', 'storage', 'screenSize', 'graphicsCard', 'displayType'].map((attribute) => (
                <tr className="border" key={attribute}>
                  <th className="border p-2 capitalize" scope="row">
                    {attribute.replace(/([A-Z])/g, ' $1')}
                  </th>
                  {comparisonList.map((product) => (
                    <td className="border p-2" key={`${product.slug}-${attribute}`}>
                      {product[attribute] || 'N/A'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductComparison;