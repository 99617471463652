import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const fetchCartItems = () => {
      const storedItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const validItems = storedItems.filter(item => item.id); // Ensure valid items
      setCartItems(validItems);
      calculateTotalPrice(validItems);
    };

    fetchCartItems();
  }, []);

  const calculateTotalPrice = (items) => {
    const total = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
    setTotalPrice(total);
  };

  const handleRemoveItem = (id) => {
    const updatedItems = cartItems.filter(item => item.id !== id);
    setCartItems(updatedItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    calculateTotalPrice(updatedItems);
  };

  const handleQuantityChange = (id, quantity) => {
    const updatedItems = cartItems.map(item =>
      item.id === id ? { ...item, quantity: Math.max(quantity, 1) } : item
    );
    setCartItems(updatedItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    calculateTotalPrice(updatedItems);
  };

  const bg = "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a";

  return (
    <section
      className="py-10 px-4 min-h-screen"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="mt-10 px-2">
        <div className="max-w-5xl mx-auto">
          <h1 className="text-2xl font-bold mb-4">Shopping Cart</h1>

          {cartItems.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            <div className="p-10 rounded-md">
              <ul className="space-y-4">
                {cartItems.map((item) => (
                  <li key={item.id} className="flex items-center justify-between p-4 border rounded-md">
                    <Link to={`/product/${item.id}`} className="flex items-center">
                      <img
                        src={`data:image/jpeg;base64,${item.imageUrl}`}
                        alt={item.ProductName}
                        className="w-16 h-16 object-cover rounded-md"
                      />
                      <div className="ml-4">
                        <span className="block font-bold">{item.ProductName}</span>
                        <span className="block text-sm text-gray-600">Product ID: {item.ProductID}</span>
                        <span className="block text-sm text-gray-600">ACIN: {item.ACIN}</span>
                      </div>
                    </Link>
                    <div className="flex items-center">
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value, 10))}
                        className="w-16 text-center border rounded-md"
                        min="1"
                      />
                      <button
                        onClick={() => handleRemoveItem(item.id)}
                        className="ml-4 px-2 py-1 text-white bg-red-500 rounded-md hover:bg-red-600"
                      >
                        Remove
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-6">
                <h2 className="text-xl font-bold">
                  Total: Rs. {totalPrice.toLocaleString()}
                </h2>
              </div>
              <div className="mt-4">
                <Link
                  to="/checkout"
                  className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
                >
                  Proceed to Checkout
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Cart;