import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Footer from './components/Footer';
import ProductDashboard from './pages/ProductDashboard';
import HomePage from './pages/HomePage';
// import HomePageC from './pages/HomePageC';
import AboutPage from './pages/AboutPage';
import Cart from './pages/Cart';
import Dashboard from './pages/Dashboard';
import ProductDetails from './pages/ProductDetails';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import Checkout from './pages/Checkout';
import PaymentPage from './pages/PaymentPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import WishlistPage from './pages/WishlistPage';

function App() {
  return (
    <Router>
    <Header/>
    <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/About' element={<AboutPage />} />
        <Route path='/Dashboard' element={<Dashboard />} />
        <Route path='/products/grouped/:slug' element={<ProductDetails />} />
        <Route path='/dashboard' element={<ProductDashboard />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path='/payment' element={<PaymentPage />} />
        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/terms&conditions' element={<TermsAndConditions />} />
        <Route path='/Wishlist' element={<WishlistPage />} />
        <Route path="/LoginPage" element={<LoginPage />} />
        <Route path='/SignUpPage' element={<SignUpPage />} />
      </Routes>
    <Footer />
  </Router>
  );
}

export default App;
