 
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WishlistPage = () => {
  // Initialize wishlist from localStorage
  const [wishlist, setWishlist] = useState(() => JSON.parse(localStorage.getItem('wishlist')) || []);
  
  const navigate = useNavigate();

  const handleProductClick = (slug) => {
    navigate(`/products/grouped/${slug}`);
  };

  // Function to remove a product from the wishlist
  const handleRemoveFromWishlist = (id) => {
    const updatedWishlist = wishlist.filter(product => product.id !== id);
    setWishlist(updatedWishlist);
    localStorage.setItem('wishlist', JSON.stringify(updatedWishlist)); // Update localStorage
  };

  const bg = "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a";

  return (
    <section 
      className="py-10 px-4 min-h-screen bg-gray-100"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl font-extrabold mb-6 text-gray-800">My Wishlist</h2>
        {wishlist.length === 0 ? (
          <p className="text-gray-500">Your wishlist is empty.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {wishlist.map(product => (
              <div key={product.id} className="flex flex-col bg-white p-6 rounded-lg shadow-lg">
                <img 
                  src={product.imageUrl} 
                  alt={product.ProductName} 
                  className="w-full object-cover h-auto rounded-md" 
                />
                <h3 className="text-lg font-bold mt-5 text-gray-800">{product.ProductName}</h3>
                <h4 className="font-bold text-lg text-orange-500 mt-4">
                  Rs. {product.price.toLocaleString('en-PK')}/-
                </h4>
                <div className="flex mt-4">
                  <button 
                    onClick={() => handleProductClick(product.slug)} 
                    className="bg-orange-500 text-white px-4 py-2 rounded mr-2"
                  >
                    View Product
                  </button>
                  <button 
                    onClick={() => handleRemoveFromWishlist(product.id)} 
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default WishlistPage;
