import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { useComparison } from '../context/CompareContext';

const ProductList = () => {
  const { addToComparison } = useComparison();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('http://www.abidcomputersmultan.com:3000/api/products/grouped');
        setProducts(response.data);
      } catch (err) {
        setError('Error fetching products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const PreviousArrow = ({ onClick }) => (
    <button
      className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white hover:bg-gray-600 rounded-full p-3 shadow-lg z-10"
      onClick={onClick}
      aria-label="Previous"
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button
      className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white hover:bg-gray-600 rounded-full p-3 shadow-lg z-10"
      onClick={onClick}
      aria-label="Next"
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </button>
  );

  PreviousArrow.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  NextArrow.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  if (loading) return <div className="text-center mt-10 text-lg">Loading...</div>;
  if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 mt-10 mb-10">
      <Slider {...sliderSettings}>
        {products.length === 0 ? (
          <div className="text-center text-gray-500">No products found</div>
        ) : (
          products.map((product) => (
            <div key={product.slug} className="p-2">
              <div className="bg-white rounded-lg shadow-md p-4 hover:shadow-xl transition-transform transform hover:-translate-y-1">
                <Link to={`/products/grouped/${product.slug}`} className="block">
                  {product.imageUrl ? (
                    <img
                      src={product.imageUrl}
                      alt={product.ProductName}
                      className="w-full h-auto object-cover rounded-md"
                    />
                  ) : (
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/woocommerce-placeholder.png?alt=media&token=27e9da3d-7159-4a01-a9ed-0a7d77473357"
                      alt="Placeholder"
                      className="w-full h-48 object-cover rounded-md"
                    />
                  )}
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold text-gray-800 truncate">
                      {product.ProductName}
                    </h3>
                    <p className="text-gray-500 text-sm">
                      CPU: {product.processor}, Generation: {product.generation}
                    </p>
                    <p className="text-xl font-bold text-orange-500 mt-2">
                      Rs. {product.price}
                    </p>
                  </div>
                </Link>
                <button
                  className="mt-4 w-full bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-lg flex items-center justify-center gap-2"
                  onClick={() => addToComparison({
                    slug: product.slug, // Unique identifier for the product
                    ProductName: product.ProductName,
                    imageUrl: product.imageUrl,
                    price: product.price,
                    processor: product.processor,
                    generation: product.generation,
                    ramMemory: product.ramMemory,
                    storage: product.storage,
                    screenSize: product.screenSize,
                    graphicsCard: product.graphicsCard,
                    displayType: product.displayType
                  })}
                  aria-label="Add to comparison"
                >
                  <FontAwesomeIcon icon={faBalanceScale} /> Compare
                </button>
              </div>
            </div>
          ))
        )}
      </Slider>
    </div>
  );
};

export default ProductList;
