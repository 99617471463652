import React, { createContext, useContext, useState } from 'react';

const ComparisonContext = createContext();

export const useComparison = () => useContext(ComparisonContext);

export const ComparisonProvider = ({ children }) => {
  const [comparisonList, setComparisonList] = useState([]);

  const addToComparison = (product) => {
    if (!product || !product.slug) {
      console.error("Product slug is required to add to the comparison list.", product);
      return;
    }
    setComparisonList((prevList) => {
      const exists = prevList.find((p) => p.slug === product.slug);
      if (exists) {
        console.warn('Product already in comparison:', product.slug);
        return prevList; // Avoid duplicate entries
      }
      console.log("Adding product with slug:", product.slug);
      // Add the product to the list
      return [...prevList, product];
    })
  };
  
  const removeFromComparison = (slug) => {
    setComparisonList((prevList) =>
      prevList.filter((item) => item.slug !== slug)
    );
  };

  return (
    <ComparisonContext.Provider
      value={{ comparisonList, addToComparison, removeFromComparison }}
    >
      {children}
    </ComparisonContext.Provider>
  );
};
